import React from 'react';
import { MarketButton, MarketRow, MarketList, MarketIcon } from '@market/react';
import { PaymentRecord } from 'rpc/model/squareup/payments/model/payment_record';
import { useTranslation } from 'react-i18next';
import { Step } from './enums/Step';
import { format as formatMoney } from 'utils/currency';
import { useHistory } from 'react-router-dom';
import { BASE_PATH } from './constants/routes';

type Props = {
  paymentRecords: PaymentRecord[];
  setCurrentStep: (step: Step) => void;
};

interface Money {
  amount: number;
  currencyCode: string;
}

const ResultList: React.FC<Props> = ({ paymentRecords, setCurrentStep }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={'flex justify-center mt-2 p-6'}>
      <div className={'flex flex-col min-w-[440px]'}>
        <main>
          <MarketButton
            iconOnly
            className="w-[48px] text-black"
            aria-label={t('common.back')}
            onClick={() => setCurrentStep(Step.FORM)}
            data-testid="back-button"
          >
            <MarketIcon name="back" slot="icon"></MarketIcon>
          </MarketButton>
          <h1 className="heading-30">
            {t('receiptLookup.list.title', { count: paymentRecords.length })}
          </h1>
          <MarketList transient data-testid="receipt-lookup-result-list">
            {paymentRecords.map((pr) => (
              <MarketRow
                variant="drill"
                key={pr.paymentToken}
                onClick={() => {
                  history.push({ pathname: `${BASE_PATH}/${pr.paymentToken}` });
                }}
              >
                <label slot="label">
                  {pr.cardTransaction?.merchant?.name1}
                </label>
                <p slot="subtext">
                  {pr.cardTransaction?.authIntent?.totalAmount
                    ? formatMoney(
                        pr.cardTransaction.authIntent
                          .totalAmount as unknown as Money
                      )
                    : ''}
                </p>
              </MarketRow>
            ))}
          </MarketList>
        </main>
      </div>
    </div>
  );
};

export default ResultList;
